import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Callout, type CalloutProps, Text } from "@tremor/react";

type ErrorBoundaryProps = CalloutProps & {
  errorMessage?: string;
};

const ErrorBoundary = ({ title, errorMessage, ...rest }: ErrorBoundaryProps) => {
  return (
    <Callout
      className="bg-red-100 border border-red-400 text-red-700"
      title={title}
      icon={ExclamationTriangleIcon}
      {...rest}
    >
      <Text className="text-red-700">
        <strong>Detalhes do erro:</strong> {errorMessage}
      </Text>
    </Callout>
  );
};

export { ErrorBoundary };
