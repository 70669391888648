import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";

import FreelerLogo from "@/assets/images/logo-backoffice.svg";

// eslint-disable-next-line import/no-relative-parent-imports
import * as packageJson from "../../../package.json";

const AuthPage = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <main className="flex justify-center items-start h-screen bg-white dark:bg-gray-800">
      <div className="p-8 w-full max-w-sm">
        <div className="flex flex-row justify-center">
          <img src={FreelerLogo} alt="Freeler logo" className="mb-32" />
        </div>
        <h2 className="text-xl text-center mb-8">{t("greeting")} 👋</h2>
        <form className="space-y-4">
          <button
            className="w-full py-2 bg-primary text-white rounded-[8px]"
            type="button"
            onClick={() => loginWithRedirect()}
          >
            {t("loginButton")}
          </button>
        </form>
        <div className="flex w-full mt-4 justify-center">
          <small className="text-gray-400">Versão: {packageJson.version}</small>
        </div>
      </div>
    </main>
  );
};

export default AuthPage;
