import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import App from "@/pages/App";
import AuthPage from "@/pages/Auth";
import ErrorPage from "@/pages/ErrorPage";
import Auth0ProviderWithRedirect from "@/providers/Auth0Provider";

const routesElements = createRoutesFromElements(
  <Route element={<Auth0ProviderWithRedirect />}>
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route
        path="freelancers"
        lazy={async () => {
          const FreelancersPage = (await import("@/pages/Freelancers")).default;
          return {
            Component: FreelancersPage,
          };
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        path="freelancers/:id"
        lazy={async () => {
          const FreelancerDetailPage = (await import("@/pages/FreelancerDetail")).default;
          return {
            Component: FreelancerDetailPage,
          };
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        path="events"
        lazy={async () => {
          const { EventsPage } = await import("@/pages/Events");
          return {
            Component: EventsPage,
          };
        }}
      />
      <Route
        path="event/:id"
        lazy={async () => {
          const { EventDetailPage } = await import("@/pages/Events/EventDetail");
          return {
            Component: EventDetailPage,
          };
        }}
      />
      <Route
        path="operations"
        lazy={async () => {
          const { OperationRootPage } = await import("@/pages/Operations");
          return {
            Component: OperationRootPage,
          };
        }}
      >
        <Route path="" element={<Navigate to="dashboard" replace />} />
        <Route
          path="dashboard"
          lazy={async () => {
            const { OperationDashboardPage } = await import("@/pages/Operations");
            return {
              Component: OperationDashboardPage,
            };
          }}
        />
      </Route>
      <Route
        path="financial"
        lazy={async () => {
          const { FinancialRootPage } = await import("@/pages/Financial");
          return {
            Component: FinancialRootPage,
          };
        }}
      >
        <Route path="" element={<Navigate to="payment-orders" replace />} />
        <Route
          path="payment-orders"
          lazy={async () => {
            const { PaymentOrdersPage } = await import("@/pages/Financial");
            return {
              Component: PaymentOrdersPage,
            };
          }}
        />
        <Route
          path="payment-orders/:id"
          lazy={async () => {
            const { PaymentOrderDetailPage } = await import("@/pages/Financial");
            return {
              Component: PaymentOrderDetailPage,
            };
          }}
        />
        <Route
          path="overtime-requests"
          lazy={async () => {
            const { OvertimeRequestsPage } = await import("@/pages/Financial");
            return {
              Component: OvertimeRequestsPage,
            };
          }}
        />
      </Route>
      <Route
        path="settings"
        lazy={async () => {
          const { SettingsRootPage } = await import("@/pages/Settings");
          return {
            Component: SettingsRootPage,
          };
        }}
      >
        <Route path="" element={<Navigate to="manage-questions" replace />} />
        <Route
          path="manage-questions"
          lazy={async () => {
            const { QuestionsPage } = await import("@/pages/Settings");
            return {
              Component: QuestionsPage,
            };
          }}
        />
        {/* MARK: you can use the bellow approach, or the above approach to create a new route in the settings path. */}
        {/* <Route path="test" Component={() => <h1>second page</h1>} /> */}
      </Route>
    </Route>
    <Route path="login" element={<AuthPage />} errorElement={<ErrorPage />} />
  </Route>,
);

/**
 * The router for the application. This is the top-level router.
 */
export const router = createBrowserRouter(routesElements);
