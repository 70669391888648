import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Toaster } from "@/components/ui/toaster";

import { MainNavbar } from "./navbar";

/**
 * The base layout for the application. This is the top-level layout that uses
 * the Auth0Provider to provide authentication to the application.
 */
const BaseLayout = () => {
  const { user, isLoading } = useAuth0();
  const { pathname, search } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  if (!user && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  return (
    <main className="bg-[#d8dee3]">
      <MainNavbar />
      <section className="mt-16 px-16 py-5">
        <Toaster />
        <Outlet />
      </section>
    </main>
  );
};

export default BaseLayout;
