import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { type AppState, Auth0Provider } from "@auth0/auth0-react";

const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;

const Auth0ProviderWithRedirect = () => {
  const navigate = useNavigate();
  /** The URL to redirect after the user login. */
  const redirectToURL = `${window.location.origin}`;

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID) {
    console.error("Missing Auth0 environment variables.");
    return <Navigate to="/login" replace />;
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectToURL,
        audience: AUTH0_AUDIENCE,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
      legacySameSiteCookie={false}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Outlet />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirect;
