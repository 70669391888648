import { createRoot } from "react-dom/client";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import * as Sentry from "@sentry/react";

import AppEntryPoint from "./App";
import "./index.css";

const BYPASS_MSW = import.meta.env.VITE_BYPASS_MSW;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (import.meta.env.MODE === "production") {
  disableReactDevTools();
}

if (import.meta.env.MODE === "development" && BYPASS_MSW === "false") {
  const { worker } = await import("@/__mocks__/browser");
  // The Vite top-level await is configured in the vite.config.ts file.
  await worker.start();
}

if (import.meta.env.MODE === "development") {
  if (import.meta.hot) {
    import.meta.hot.on(
      "vite:beforeUpdate",
      /* eslint-disable-next-line no-console */
      () => console.clear(),
    );
  }
}

if (import.meta.env.MODE === "production" && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/backoffice.staging.freeler.com.br/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<AppEntryPoint />);

// Uncomment if you want to see the Lighthouse report in the console
// import reportWebVitals from './reportWebVitals'
// reportWebVitals(console.log)
