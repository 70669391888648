import { StrictMode } from "react";
import { RouterProvider } from "react-router-dom";

import AppProviders from "./providers";
import { router } from "./router";

const AppEntryPoint = () => {
  return (
    <StrictMode>
      <AppProviders>
        <RouterProvider router={router} />
      </AppProviders>
    </StrictMode>
  );
};

export default AppEntryPoint;
