import { QueryCache, QueryClient, type QueryClientConfig } from "@tanstack/react-query";

type QueryCacheConfig = typeof QueryCache.prototype.config;

const MODE = import.meta.env.MODE;

function createQueryCache(config?: QueryCacheConfig): QueryCache {
  return new QueryCache(config);
}

function createQueryClient(config?: QueryClientConfig): QueryClient {
  return new QueryClient(config);
}

const queryCache = createQueryCache();
const queryClient = createQueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: MODE === "test" ? false : 3,
    },
  },
});

export { queryClient, queryCache };
