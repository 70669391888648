import { type PropsWithChildren } from "react";
import { I18nextProvider } from "react-i18next";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import i18n from "@/lang/i18n";
import { queryClient } from "@/utils/queryClient";

import { PermissionsProvider } from "./PermissionsProvider";

type Props = PropsWithChildren<Record<string, unknown>>;

const IS_DEV_MODE = import.meta.env.DEV;

const AppProviders = ({ children }: Props) => {
  return (
    <I18nextProvider i18n={i18n}>
      <PermissionsProvider>
        <QueryClientProvider client={queryClient}>
          {children}
          {IS_DEV_MODE && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </PermissionsProvider>
    </I18nextProvider>
  );
};

export default AppProviders;
