import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { Card } from "@tremor/react";

import { ErrorBoundary } from "@/components/ErrorBoundary";

const ErrorPage = () => {
  const error = useRouteError() as Record<string, never>;
  // These two lines are just for debugging purposes and cannot be removed.
  console.error("RR Error: ", error);
  console.error("isRouteErrorResponse: ", isRouteErrorResponse(error));

  return (
    <Card id="error-page">
      <ErrorBoundary
        title="Tivemos um erro ao carregar a aplicação"
        errorMessage={error?.statusText || error?.message}
      />
    </Card>
  );
};

export default ErrorPage;
